<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="!isConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="revertReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        {{
              $t(
                'Components.Reservations.ModalRevertRevision.Article_AreYouSure'
              )
            }}
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isConfirmed"
          class="has-text-left"
          :label="$t('Components.Options.ModalDeleteOption.Text_YesSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    revision: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isConfirmed: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {},

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    revertReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let reservation = JSON.parse(self.revision.ReservationObject)

        reservationProvider.methods
          .saveReservation(reservation, self.changeAllInSet)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              self.$emit('reservationReverted', self.revision)

              // Set timer to close popup in in 1,5 seconds
              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
            self.isSavingSuccess = true
          })
      }
    },
  },
}
</script>
